import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthResponse} from './interfaces';

const devMode = '&Session=1';
const baseUrlV3 = 'https://1eyeonu.com/APIv3/?path=';

export interface Booking {
    id: string;
    date: string;
    client: string;
    price: string;
    b_start_date: string;
    client_name: string;
    user_name: string;
    buser_full_name: string;
    bt_name: string;
    b_created: string;
    duration: string;
    pl_name: string;
    pay_name: string;
    info: string;
}

@Injectable({providedIn: 'root'})
export class BookingsService {
    bookings: [any];

    constructor(private http: HttpClient) {

    }

    isAgentBooking(id): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=isAgentBooking&id=' + id, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getBookings(): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getBookings', { // getBookings
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getAlarmBookings(): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getAlarmBookings', {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getBodyguards(): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getBodyguardsForBookings', {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getBodyguardsForNewBooking(type): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getBodyguardsForNewBooking&type=' + type, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getCarsAll(id, active): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getCarsAll&id=' + id + '&active=' + active, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    saveSetting(value): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=saveSetting&value=' + value, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });


        // return this.http.get<any>('https://1eyeonu.com/APIv2/?action=saveSetting&value=' + value, {
        //     headers: new HttpHeaders({
        //         authorization: localStorage.getItem('access_key')
        //     })
        // });
    }

    getPlaces(name = null): Observable<any> {
        const del: string = name != null ? '&delete=' + name : '';
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getPlaces' + del, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getBookingsWithParameters(parameters): Observable<any> {
        return this.http.get<Booking[]>('https://1eyeonu.com/APIv2/?action=getBookings' + parameters, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getBooking(id): Observable<any> {
        // return this.http.get<Booking[]>('https://1eyeonu.com/APIv2/?action=getBooking' + '&id=' + id + '&authorization=' + localStorage.getItem('access_key'), {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getBooking' + '&id=' + id, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    updateDriverStatus(bookingId, currentStatus): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=updateDriverStatus' + '&id=' + bookingId + '&status=' + currentStatus, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getCars(): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getCars', {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getAlarmBooking(id): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getAlarmBooking' + '&id=' + id, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }


    sendAlarmYouSign(id): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=sendAlarmYouSign' + '&id=' + id, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }


    addBooking(status, bookType, sourceId, clientName, clientEmail, clientPhone, pickupPlace, pickup, dropoffPlace, dropoff, startDate, backDate, timeHour, timeMin, endDate, duration, durationMin, paymentMethod, price, realPrice, info, bodyguardType, bodyguardId, relatedBooking = null, partner = null): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=addNewBooking', {
            status,
            bookType,
            sourceId,
            clientName,
            clientEmail,
            clientPhone,
            pickupPlace,
            pickup,
            dropoffPlace,
            dropoff,
            startDate,
            backDate,
            timeHour,
            timeMin,
            endDate,
            duration,
            durationMin,
            paymentMethod,
            price,
            realPrice,
            info,
            bodyguardType,
            bodyguardId,
            relatedBooking,
            partner,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    addAlarmBooking(data, zones, current_project_id = null, clientPhone, clientContactPhone): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=addNewAlarmBooking', {
            data,
            zones,
            clientPhone,
            clientContactPhone
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    updateAgent(bookingId, bodyguardId) {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=updateAgent', {
            bookingId,
            bodyguardId,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    updateBooking(id, bookType, sourceId, status, clientName, clientEmail, clientPhone, duration, durationMin, startDate, timeHour, timeMin, backDate, timeHourBack, timeMinBack, endDate, placePickUp, pickup, placeDropOff, dropoff, paymentMethod, price, realPrice, tips, info, bodyguardType, bodyguardId, car, workHours, passengers = 1, partner = null, fullDisc = false): Observable<Booking[]> {
        console.log('updateBooking');
        return this.http.post<Booking[]>('https://1eyeonu.com/APIv2/?action=updateBooking', {
            id,
            bookType,
            sourceId,
            status,
            clientName,
            clientEmail,
            clientPhone,
            duration,
            durationMin,
            startDate,
            timeHour,
            timeMin,
            backDate,
            timeHourBack,
            timeMinBack,
            endDate,
            placePickUp,
            pickup,
            placeDropOff,
            dropoff,
            paymentMethod,
            price,
            realPrice,
            tips,
            info,
            bodyguardType,
            bodyguardId,
            car,
            workHours,
            passengers,
            partner,
            fullDisc,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getSales(): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getSales', {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    addBookingInExistInvoice(bookingId, invoiceId): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=addBookingInExistInvoice', {
            bookingId,
            invoiceId,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    // tslint:disable-next-line:variable-name
    saveInvoice(bookingId, currency, client_name, client_phone, client_email, client_address, comment, paid, payment_method, mode, invoiceId, send): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=saveInvoice&PHPSESSID=' + localStorage.getItem('access_key'), {
            bookingId,
            currency,
            client_name,
            client_phone,
            client_email,
            client_address,
            comment,
            paid,
            payment_method,
            mode,
            invoiceId,
            send,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    deleteInvoice(id): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=deleteInvoice', {
            id,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }
}
